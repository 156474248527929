import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import SelectPintoCategory from '../../../components/modal/SelectPintoCategory';
import { ContentType, PintoCategory } from '../../../core/graphql/types';
import useQueryPintoCategories from '../../../hooks/useQueryPintoCategories';
import { findCategoryById } from '../../../utils/categoryUtils';

interface IPropsSelectPintoCategory {
  name: string;
  categoryId?: number;
}

const SelectPintoCategoryWrapper = (props: IPropsSelectPintoCategory) => {
  const { setValue } = useFormContext();
  const { pintoCategories: allCategories } = useQueryPintoCategories();

  const { categoryId, name } = props;
  const [categoryName, setCategoryName] = useState<string>('');

  const onSetPintoCategory = useCallback(
    (categories) => {
      const selectedCategoryId = categories?.[(categories?.length || 0) - 1]._id;
      const contentType = categories?.[(categories?.length || 0) - 1].contentType;
      const categoryName = categories?.[(categories?.length || 0) - 1].name;

      setValue?.(name, selectedCategoryId);
      setValue?.('contentType', contentType);

      if (contentType !== ContentType.Comic) setValue('target.anyWritingTypes', []);
      setCategoryName(categoryName); // Update category name
    },
    [name, setValue],
  );

  useEffect(() => {
    if (allCategories?.pintoCategories && categoryId) {
      const categoryHierarchy = findCategoryById(allCategories.pintoCategories.filter((category) => category !== null) as PintoCategory[], categoryId);
      if (categoryHierarchy) {
        onSetPintoCategory(categoryHierarchy);
      }
    }
  }, [allCategories, categoryId, onSetPintoCategory]);

  return (
    <div>
      <label className="label">
        <span className="label-text text-systemGrays02LabelSecondary">
          Category <span className="text-colorsBrandRed01Primary">*</span>
        </span>
      </label>
      <div className="space-x-16">
        <div
          className={classNames('py-16 badge', {
            'text-colorsBrandRed01Primary bg-transparent border-colorsBrandRed01Primary': !!categoryId,
            'badge-outline': !categoryId,
          })}
        >
          {`CategoryId: ${categoryId || 'N/A'}`} , {`Name: ${categoryName || 'N/A'}`}
        </div>
        <SelectPintoCategory
          openModalLabel={categoryId ? 'change Category' : 'select category'}
          onConfirm={onSetPintoCategory}
          name={name}
          allCategories={(allCategories?.pintoCategories ?? []).filter((category) => category !== null) as PintoCategory[]}
        />
      </div>
    </div>
  );
};

export default SelectPintoCategoryWrapper;
