import { FieldValues, UseFormRegister, useFormContext } from 'react-hook-form';

import Input from '../../../components/form/Input';
import { IConfigItemType } from '../validates/sectionBuilderSchema';

import RenderObjectLabel from './RenderObjectLabel';

export interface IPropsEditorReviewItem {
  type: IConfigItemType.EditorReviewEbook;
  _id?: string;
  review?: string;
  reviewBy?: string;
}

interface IPropsEditorReviewSectionItem {
  index: number;
  itemData?: IPropsEditorReviewItem;
  register: UseFormRegister<FieldValues>;
}

const EditorReviewSectionItem = (props: IPropsEditorReviewSectionItem) => {
  const { index, itemData, register } = props;

  // Retrieve watch and errors from useFormContext
  const {
    watch,
    formState: { errors },
  } = useFormContext();

  // Watch specific input fields for dynamic length tracking
  const reviewValue = watch(`configItems.${index}.review`, '');
  const reviewByValue = watch(`configItems.${index}.reviewBy`, '');

  return (
    <div className="flex justify-between space-x-16">
      <div className="w-full collapse">
        <input type="checkbox" className="peer" />
        <div className="collapse-title bg-systemGrays08FillQuaternary text-systemGrays01LabelPrimary peer-checked:bg-systemGrays08FillQuaternary peer-checked:text-systemGrays01LabelPrimary flex justify-between after:content-['+'] peer-checked:after:content-['-'] ease-out duration-300">
          <span>
            <span>Editor`s Pick </span>
            <RenderObjectLabel
              objectLists={[
                { key: 'Ebook Id', value: itemData?._id, required: true },
                { key: 'Review', value: itemData?.review, required: true },
                { key: 'Written By', value: itemData?.reviewBy, required: true },
              ]}
            />
          </span>
        </div>
        <div className="collapse-content text-systemGrays01LabelPrimary peer-checked:bg-systemGrays08FillQuaternary peer-checked:text-systemGrays01LabelPrimary">
          <div className="space-y-8">
            <Input label="Ebook Id" name={`configItems.${index}._id`} placeholder="ebook ID" helperText="Input ebook ID" register={register} />
            <Input
              label="Review"
              name={`configItems.${index}.review`}
              placeholder="เขียนรีวิว"
              helperText={`${reviewValue?.length ?? 0}/100 characters`}
              register={register}
              isInvalid={!!errors?.configItems?.[index]?.review}
              maxLength={100}
            />

            <Input
              label="Written By"
              name={`configItems.${index}.reviewBy`}
              placeholder="ชื่อผู้เขียน"
              helperText={`${reviewByValue?.length ?? 0}/50 characters`}
              register={register}
              isInvalid={!!errors?.configItems?.[index]?.reviewBy}
              maxLength={50}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditorReviewSectionItem;
