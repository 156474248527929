const images = {
  logo: '/a/images/Fictionlog_Logo_Yellow.svg',
  bgLogin: '/a/images/background-login-page.jpg',
  fictionlog: {
    workspaceLogo: '/a/images/fictionlog.webp',
  },
  pinto: {
    logo: '/a/images/pinto/logo.svg',
    logoDark: '/a/images/pinto/logo-dark.svg',
    workspaceLogo: '/a/images/pinto/favicon-pinto-64x64.ico',
    default: {
      profileDefault: '/a/images/pinto/default/blank-profile.png',
      ebookCover: '/a/images/pinto/default/blank-ebook-cover.png',
      bannerCover: '/a/images/pinto/default/blank-banner-cover.svg',
    },
    sections: {
      bookCover: '/a/images/pinto/sections/book_cover.jpg',
      bookCover2: '/a/images/pinto/sections/book_cover_2.jpg',
      bookBanner: '/a/images/pinto/sections/book_banner.jpg',
      linkBanner: '/a/images/pinto/sections/link_banner.jpg',
      linkBannerFullWidth: '/a/images/pinto/sections/link_banner_full_width.jpg',
      mainBanner: '/a/images/pinto/sections/main_banner.jpg',
      rank: '/a/images/pinto/sections/rank.jpg',
      tagCloud: '/a/images/pinto/sections/tag_cloud.jpg',
      testimonial: '/a/images/pinto/sections/testimonial.jpg',
      editorPick: '/a/images/pinto/sections/editor_pick.png',
    },
  },
};

export default images;
