import { PintoCategory } from '../core/graphql/types';

/**
 * Recursively finds a category and its hierarchy by ID.
 * @param categories - The array of PintoCategory objects.
 * @param id - The ID to search for.
 * @returns An array representing the hierarchy of the category or null if not found.
 */
export const findCategoryById = (categories: PintoCategory[], id: number): PintoCategory[] | null => {
  for (const category of categories) {
    if (category._id === id.toString()) {
      return [category];
    }

    if (category.childrens?.length) {
      const result = findCategoryById(category.childrens.filter((child) => child !== null) as PintoCategory[], id);
      if (result) {
        return [category, ...result];
      }
    }
  }
  return null;
};
